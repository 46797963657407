<script>
import WaveSurfer from 'wavesurfer.js'

import axios from 'axios';

export default {
  data() {
    return {
      waveSurfer: null,
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  mounted: function () {
    this.waveSurferInit();
  },
  components: {
  },
  methods: {
    waveSurferInit() {
      if(this.data.episode.urlAudioFile && this.data.peaksData) {
        axios.get('/api/episode/get-peaks/' + this.$route.params.id)
        .then((response) => {
          var peaks = response.data;
          if(peaks.length > 0) {
            this.waveSurfer = WaveSurfer.create({
              container: '#waveView_' + this.data.episode.id,
              waveColor: '#abc',
              progressColor: '#383351',
              //url: this.data.episode.urlAudioFile,
              height: 200,
              hideScrollbar: false,
              autoScroll: false,
              media: document.getElementById('episodePlayer'),
              barWidth: 2,
              barGap: 1,
              barRadius: 2,
              duration: this.data.episode.stat.seconds,
              peaks: [ peaks ],
            });

            this.waveSurfer.on('ready', this.waveReady);
            this.waveSurfer.on('click', this.waveClick);
            this.waveSurfer.on('finish', this.waveFinish);
          }

        })
        .catch(function (e) {
          console.error("Loading data peaks error:", e);
        });
        
      }
    },
    waveClick() {
      this.waveSurfer.play()
    },
    waveReady() {
    },
    waveFinish() {
    },
    waveSurfDestroy() {
        if(this.waveSurfer) {
            this.waveSurfer.destroy();
            this.waveSurfer = null;
        }
    },
  },
  computed: {
    rssFeed: function() {
      return process.env.VUE_APP_URL + 'tools/rss/podcast/' + this.data.podcast.link;
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-header">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <h6 class="card-title mb-0">
            About
            <a :href="rssFeed" target="_blank" v-if="data.podcast.share === 2"><i class="ri-rss-line"></i></a>
          </h6>
        </div>
        <div class="flex-shrink-0">
          <ul class="list-inline d-flex align-items-center mb-0">
            <li class="list-inline-item" v-for="p in data.links" v-bind:key="p">
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 1 && p.url != ''">
                <i class="ri-facebook-line"></i>
              </a>
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 2 && p.url != ''">
                <i class="ri-twitter-line"></i>
              </a>
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 3 && p.url != ''">
                <i class="ri-instagram-line"></i>
              </a>
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 4 && p.url != ''">
                <i class="ri-linkedin-line"></i>
              </a>
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 5 && p.url != ''">
                <i class="ri-github-line"></i>
              </a>
              <a class="align-middle" target="_blank" :href="p.url" v-if="p.type === 6 && p.url != ''">
                <i class="ri-telegram-line"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div :id="'waveView_' + data.episode.id" class="ms-2 me-2 mb-3" v-if="data.episode.urlAudioFile && data.peaksData"></div>
      <p class="pre-line m-0" v-if="data.episode.memo">{{ data.episode.memo }}</p>
      <div class="d-flex flex-wrap gap-2 fs-16 mt-3" v-if="data.tags != null && data.tags.length > 0">
        <div class="badge fw-medium badge-soft-light" v-for="p in data.tags" v-bind:key="p">
          <router-link :to="{ name: 'episodes-tag', params: { value: p.title } }">
          #{{ p.title }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
