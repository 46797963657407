<script>
export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  computed: {
  }
};
</script>

<template>
  <div class="flex-shrink-0">
    <router-link :to="{ name: 'profile', params: { login: data.profile.login } }">
      <img :src="data.profile.urlAvatar" alt="" class="avatar-xs rounded-circle" />
    </router-link>
  </div>
  <div class="flex-grow-1 ms-3">
      <h5 class="fs-13">
        <router-link :to="{ name: 'profile', params: { login: data.profile.login } }">
          {{ data.profile.login }} <small class="text-muted">{{ data.profile.displayName }}</small>
        </router-link> - <small class="text-muted">{{ new Date(data.createdAt).toLocaleString() }}</small>
      </h5>
      <p class="text-muted pre-line">{{ data.memo }}</p>
  </div>
</template>
